@use "../global" as *;

.contact-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  color: $text-black;
  padding: 10rem 2rem;
  background-image: url("/src/images/banners/bg-contact.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
    text-align: center;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 41rem;

    @media (max-width: 950px) {
      margin: 0 auto;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: $h2-size;
      line-height: 1.3;
      margin-bottom: 2rem;
    }

    p {
      font-size: $p-size;
      font-family: $text-font;
      color: $text-gray;
      line-height: 1.6;
      margin-bottom: 2rem;
    }

    a {
      text-decoration: none;
      color: $text-black;
      font-size: $p-size;
      font-weight: 500;
      transition: all 0.2s;
      margin-bottom: 0.5rem;

      &:hover {
        color: $text-orange;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: $p-size;
        font-weight: 600;
        margin-bottom: 1rem;

        b {
          color: $text-orange;
        }
      }

      input {
        background-color: #f2f2f2;
        padding: 19px 30px 19px 30px;
        font-size: $p-size;
        border: none;
        outline: none;
        margin-bottom: 2.3rem;
      }

      textarea {
        background-color: #f2f2f2;
        height: 18rem;
        padding: 19px 30px 19px 30px;
        font-size: $p-size;
        border: none;
        outline: none;
        margin-bottom: 2.5rem;
      }

      button {
        background-color: #ff4d30;
        padding: 1.8rem 3rem;
        border-radius: 0.3rem;
        box-shadow: 0 10px 15px 0 rgb(255 83 48 / 35%);
        transition: all 0.3s;
        border: 2px solid #ff4d30;
        color: white;
        font-size: 1.8rem;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          box-shadow: 0 10px 15px 0 rgb(255 83 48 / 60%);
          background-color: #fa4226;
        }
      }
    }
  }
}